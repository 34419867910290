import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import GatsbyImage from 'gatsby-image';

import Layout from "../components/layout"
import SEO from "../components/seo"

interface Node {
   [key: string]: string | any
}

interface PageProps {
  allBooks: {
    edges: [{
      node: Node
    }]
  }
};
const query = graphql`
query {
  allBooks {
    edges {
      node {
        title
        isbn
      }
    }
  }
}`;

const imageQuery = graphql`
query {
  allFile(
    filter: {fields: {BlogImages: {eq: "true"}}}
  ){
    edges {
      node {
        childImageSharp {
          sizes(maxWidth: 1240 ) {
            ...GatsbyImageSharpSizes
          }
        }
        id
        fields {
          # 一応メタ情報も取得する
          BlogImages
          link
          FileName
        }
      }
    }
  }
}`;

const getImage = (names: string[]): Node[] => {
  const images = useStaticQuery(imageQuery);  
  return images.allFile.edges.filter((edge: Node) => {
    if (names.includes(edge.node.fields["FileName"])) {
       return true
    }
  }) 

}

const Page3 = () => {
  // const data: PageProps = useStaticQuery(query);
  // {data.allBooks.edges[0].node["title"]}
  //     {data.allBooks.edges[0].node["isbn"]}
  const thisPageUseImages = ['ffxiv_20190814_011927_204.png', 'ffxiv_20190814_011357_825.png']
  const imgs = getImage(thisPageUseImages)
  const imgCompo = () => {
    const comps = [];
    for(let i = 0; i < imgs.length; i++) {
      comps.push(<GatsbyImage sizes={imgs[i].node.childImageSharp.sizes} />)
      comps.push(<br />)  
    }
    return comps
  }
  
  // {images.allFile.edges[0].node.fields.FileName}
  return (
    <Layout>
      
      {imgCompo()}
      <SEO title="Page two" />
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  );
};

export default Page3
